import * as Constant from './constants';
import * as Api from '../apis/emori';

export const setEmoriMonth = payload => ({
  type: Constant.SET_MONTH,
  payload: payload,
});

export const setEmoriDate = day => ({
  type: Constant.SET_DATE,
  payload: day,
});

export const setEmoriRelationCorporate = corporate => ({
  type: Constant.SET_RELATION_CORPORATE,
  payload: corporate,
});

export const setEmoriRelationRoom = room => ({
  type: Constant.SET_RELATION_ROOM,
  payload: room,
});

export const setEmoriRelationSearch = search => ({
  type: Constant.SET_RELATION_SEARCH,
  payload: search,
});

// export const setEmoriDate = (day) => {
//   return (dispatch, getState) => {
//     // dispatch({ type: Constant.PROMOTION_GET_REQUEST });
//     // return Api.getListPromotionAppointment()
//     //   .then(payload => {
//     //     if (filter) {
//     //       const { session } = getState();
//     //       let member = session.user.is_member;
//     //       let promotions;
//     //       promotions = payload.results.filter(
//     //         val => val.promotion.is_from_membership && val.promotion.available,
//     //       );
//     //       let coupon =
//     //         promotions.length > 0 ? promotions[0]['promotion'].id : null;

//     //       dispatch({ type: Constant.SET_PROMOTION, payload: coupon });
//     //     }
//     //     dispatch({ type: Constant.PROMOTION_GET_SUCCESS, payload });
//     //     return payload;
//     //   })
//     //   .catch(message => {
//     //     dispatch({ type: Constant.PROMOTION_GET_FAILED, message });
//     //   });
//     dispatch({ type: Constant.SET_DATE, payload: day });
//     return 
//   };
// };

export const getEmoriList = () => {
  return dispatch => {
    dispatch({ type: Constant.EMORI_GET_REQUEST });
    return Api.getEmoriList()
      .then(payload => {
        dispatch({ type: Constant.EMORI_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.EMORI_GET_FAILED, message });
      });
  };
};

export const getEmoriRoomList = () => {
  return dispatch => {
    dispatch({ type: Constant.EMORI_ROOM_LIST_GET_REQUEST });
    return Api.getEmoriRelationRoomList()
      .then(payload => {
        dispatch({ type: Constant.EMORI_ROOM_LIST_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.EMORI_ROOM_LIST_GET_FAILED, message });
      });
  };
};

export const getEmoriRoomProfileList = (roomId, search='', page=0, limit=20) => {
  return dispatch => {
    dispatch({ type: Constant.EMORI_ROOM_PROFILE_LIST_GET_REQUEST });
    return Api.getEmoriRelationProfileList(roomId, limit, search, page)
      .then(payload => {
        dispatch({ type: Constant.EMORI_ROOM_PROFILE_LIST_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.EMORI_ROOM_PROFILE_LIST_GET_FAILED, message });
      });
  };
};
