import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/v2/Layout';
import ProfileAdminEmori from '../../../components/ProfileAdminEmori';
import { getUrlParams } from '../../../helpers/location';
import { Avatar, Chip, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { renderRegularDate } from '../../../helpers/date';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import { createEmoriProfileSelector } from '../../../reducers/emori';
import { getEmoriRoomProfileList, setEmoriRelationSearch } from '../../../actions/emori';
import SearchMember from '../../../components/SearchMember';
import { getEmoriRelationProfileList } from '../../../apis/emori';
import ManageMemberEmori from '../../../components/ManageMemberEmori';

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = getUrlParams(location.search);
  const { room } = params;
  // const members = useSelector(createEmoriProfileSelector);
  const [ members, setMembers ] = React.useState('');
  const [ searchText, setSearchText ] = React.useState('');
  const { search } = useSelector(state => state.emori);

  React.useEffect(() => {
    getProfile();
  }, [searchText]);

  const getProfile = async () => {
    try {
      dispatch(getEmoriRoomProfileList(room, '', 0, 0));
      const res = await getEmoriRelationProfileList(room, 20, searchText);
      setMembers(res.results);
      dispatch(setEmoriRelationSearch(searchText));
    } catch (e) {}
  }

  if (!members) return false;

  return (
    // loading in layout โปรไฟล์เจ้าของห้อง
    <Layout bg='bg4' title='จัดการรายชื่อ'>
      <SearchMember placeholder={'ค้นหาชื่อ'} onSearch={setSearchText} room={room}/>
      <ManageMemberEmori search={search} members={members} />
    </Layout>
  );
};
